<template>
  <div class="thesis-quiz">
    <button
      v-if="quizData.iframe && !completed"
      class="close-quiz-button"
      @click="closeIframe"
    >
      <img :src="CloseIcon">
    </button>
    <ThesisForm>
      <template #introView>
        <LeadCapture
          v-if="quizData.lead_capture.intro_slot"
          :content="quizData.lead_capture"
          @handleSubmit="handleLeadCapture"
        />
      </template>
      <template #loaderView>
        <Loader
          :content="quizData.loader"
          @loaded="setLoaded"
        />
      </template>
      <template #resultsView>
        <component
          :is="resultsComponent"
          :content="quizData.results"
          :leadCaptureContent="quizData.lead_capture"
          :dataMap="quizData.data_map"
          :results="results"
          :email="email"
          @quizCompleteEvents="quizCompleteEvents"
        />
      </template>
    </ThesisForm>
  </div>
</template>

<script>

import Loader from '../components/shared/Loader.vue'
import StrengthResults from '../components/quiz/strength/StrengthResults.vue'
import LeadCapture from '../components/shared/LeadCapture.vue'

import MembershipResults from '../components/quiz/membership/MembershipResults.vue'
import * as types from '../action-types'
import CloseIcon from '../../assets/images/quiz_b/x.svg'

export default {
  components: {
    Loader,
    StrengthResults,
    LeadCapture,
    MembershipResults,
  },
  props: {
    experimentType: {
      type: String,
      default: '',
    },
    hide: {
      type: Function,
      default: () => console.log('Add hide prop to hide component'),
    },
  },

  data() {
    return {
      completed: false,
      CloseIcon,
      email: '',
      phone: '',
    }
  },

  computed: {
    quizData() { return this.$store.getters.QUIZDATA || false },
    results() { return this.$root.$store.getters.RESULTS },
    resultsComponent() { return this.quizData.results.component },
    hasLoader() { return this.quizData.loader.delay },
    quizID() { return this.quizData.quiz_id || '' },
  },
  mounted() {
    this.trackStartRudderstack()
  },

  methods: {
    setLoaded() {
      const vm = this
      vm.$root.$store.dispatch(types.SET_LOADED)
    },
    closeIframe() {
      window.parent.postMessage('closeIframe', '*')
    },
    handleLeadCapture(event) {
      this.email = event.target.email.value
      this.phone = event.target.phone && event.target.phone.value
      this.$root.$store.dispatch(types.QUIZ_STARTED)
    },
    trackStartRudderstack() {
      const quizID = `Thesis ${this.quizID} Quiz Start`
      if (typeof window.rudderanalytics !== 'undefined') {
        window.rudderanalytics.track(quizID)
      }
    },
    klaviyoDataPassthrough(email, phone, results) {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      const body = JSON.stringify({
        email,
        ...results,
      })

      const request = {
        method: 'POST',
        headers,
        body,
      }

      fetch('https://ambassador.thesistestingapps.com/tonal/klaviyo/subscribe', request)
        .then(response => response.text())
        .then(response => console.log(response))
        .then(() => this.klaviyoQuizComplete(email))
        .catch(error => console.log('error', error))
    },
    klaviyoQuizComplete(email) {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      // Override quiz name used in Klaviyo Event
      const quizName = this.quizID === 'Strength' ? 'Strength Score' : this.quizID

      const body = JSON.stringify({
        event: `${quizName} Quiz Complete`,
        email,
      })
      const request = {
        method: 'POST',
        headers,
        body,
      }
      fetch('https://ambassador.thesistestingapps.com/tonal/klaviyo/track', request)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error))
    },
    attentiveDataPassthrough(email, phone, results) {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      const body = JSON.stringify({
        user: {
          phone,
          email,
        },
        properties: results,
        signUpSourceId: 255811,
      })

      const request = {
        method: 'POST',
        headers,
        body,
        redirect: 'follow',
      }

      fetch('https://ambassador.thesistestingapps.com/tonal/attentive', request)
        .then(response => response.text())
        .then(result => {
          if (result === 'Successfully submitted form.') {
            this.completed = true
          }
        })
        .catch(error => console.log('error', error))
    },
    quizCompleteEvents(results, email = this.email, phone = this.phone) {
      // Thixel
      if (typeof window.thix !== 'undefined') {
        window.thix('event', 'onsite.complete')
      }

      // Attentive
      this.attentiveDataPassthrough(email, phone, results)

      // Klaviyo
      this.klaviyoDataPassthrough(email, phone, results)

      // Rudderstack
      if (typeof window.rudderanalytics !== 'undefined') {
        window.rudderanalytics.identify(
          email,
          { email },
        )
        window.rudderanalytics.track(
          `Thesis ${this.quizID} Quiz Complete`,
          { ...results, email, phone },
        )
      }
      this.completed = true
    },
  },
}
</script>

<style lang="scss" scoped>

.thesis-quiz {
  width: 100vw;
  top: 0;
  left: 0;
  background-color: black;
  color: #fff;
}
</style>
