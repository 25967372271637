export const RESULTS = "/results/RESULTS";

// Mutations
export const MUTATE_UPDATE_RESULTS = '/results/MUTATE_UPDATE_RESULTS'
export const MUTATE_UPDATE_CONTENT_RESULTS = '/results/MUTATE_UPDATE_CONTENT_RESULTS'
export const MUTATE_INCREMENT_CORRECT_ANSWERS_COUNT =
  "/results/MUTATE_INCREMENT_CORRECT_ANSWERS_COUNT";

// Actions
export const UPDATE_RESULTS = '/results/UPDATE_RESULTS'
export const UPDATE_CONTENT_RESULTS = '/results/UPDATE_CONTENT_RESULTS'
export const INCREMENT_CORRECT_ANSWERS_COUNT =
  "/results/INCREMENT_CORRECT_ANSWERS_COUNT";
export const SUBMIT_ONE_PAGE = "/results/SUBMIT_ONE_PAGE";
export const ONE_PAGE_VALID = "ONE_PAGE_VALID";
export const ADVANCE_QUIZ = "ADVANCE_QUIZ";
export const QUIZ_STARTED = "QUIZ_STARTED";
export const QUIZ_COMPLETED = "QUIZ_COMPLETED";
export const SET_LOADED = "SET_LOADED";
export const ONE_PAGE_SELECTED = "ONE_PAGE_SELECTED";
export const UPDATE_MULTISELECT = "UPDATE_MULTISELECT";
export const SET_FULLREVEALED = "SET_FULLREVEALED";
