const path = window.location.pathname;
const category = "quiz";
const { gtag, fbq, ga, dataLayer } = window;
const trackLoadEvent = action => {
  // IF GTAG:
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  if (typeof gtag !== "undefined") {
    gtag("event", action, {
      event_category: category,
      non_interaction: true,
      path
    });
  }

  // IF Facebook:
  // https://developers.facebook.com/docs/marketing-api/audiences-api/pixel#advanced
  if (typeof fbq !== "undefined") {
    fbq("trackCustom", action, {
      event_category: category,
      path
    });
  }

  // IF GA:
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  if (typeof ga !== "undefined") {
    ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      nonInteraction: 1,
      path
    });
  }

  if (typeof dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "gaEventTrigger",
      gaEventCategory: category,
      gaEventOrigin: path,
      gaEventAction: action
    });
  }

  if (typeof thix !== 'undefined') {
    window.thix('event', action);
  }
};

const trackClickEvent = data => {
  let action;
  let answerValue;

  if (typeof data === "string") {
    action = "cta-clicked";
    answerValue = data;
  } else {
    action = `Q${data.questionIndex + 1}`;

    if (!Array.isArray(data.content)) {
      answerValue = data.content;
    } else {
      // used for multiselect questions
      answerValue = data.content.map(obj => obj.selection).join(", ");
    }
  }

  // IF GTAG:
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  if (typeof gtag !== "undefined") {
    gtag("event", action, {
      event_category: category,
      event_label: answerValue,
      path
    });
  }

  // IF Facebook:
  // https://developers.facebook.com/docs/marketing-api/audiences-api/pixel#advanced
  if (typeof fbq !== "undefined") {
    fbq("trackCustom", action, {
      event_category: category,
      event_label: answerValue,
      path
    });
  }

  // IF GA:
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  if (typeof ga !== "undefined") {
    ga("send", {
      hitType: "event",
      eventAction: action,
      eventCategory: category,
      eventLabel: answerValue,
      path
    });
  }

  if (typeof dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "gaEventTrigger",
      gaEventCategory: category,
      gaEventOrigin: path,
      gaEventAction: action,
      gaEventLabel: answerValue
    });
  }
};
export { trackClickEvent, trackLoadEvent };
