<template>
  <div class="tf-results">
    <transition
      v-if="showLeadCapture && !leadCaptureContent.intro_slot"
      name="fade"
      mode="out-in"
    >
      <LeadCapture
        v-if="showLeadCapture"
        :content="leadCaptureContent"
        :resultsVar="resultsVar"
        class="strength-email-var-2"
        @handleSubmit="handleSubmit"
      />
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <section
        :class="resultsVarClass"
      >
        <figure v-if="content.image">
          <img :src="content.image">
        </figure>

        <div class="tf-cover-content">
          <StrengthScoreDetails
            :content="content"
            :score="getStrengthScore()"
          />
          <ExpandedResults
            v-if="content.expanded_results"
            :content="content"
          />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import LeadCapture from '../../shared/LeadCapture.vue'
import StrengthScoreDetails from './StrengthScoreDetails.vue'
import ExpandedResults from './ExpandedResults.vue'

import { calcQuizResults } from '../../../utils/strength-score'
import { trackLoadEvent, trackClickEvent } from '../../../utils/tracking/index'

export default {
  components: {
    LeadCapture,
    ExpandedResults,
    StrengthScoreDetails,
  },

  props: {
    content: {
      type: Object,
      default: () => {},
    },
    leadCaptureContent: {
      type: Object,
      default: () => {},
    },
    dataMap: {
      type: Object,
      default: () => {},
    },
    countdown: {
      type: Number,
      default: 30,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Array,
      default: () => [],
    },
    quizCompleteEvents: {
      type: Function,
      default: () => console.log('pass quizCompleteEvents prop to submit tracking events'),
    },
  },

  data() {
    return {
      showLeadCapture: true,
      showMainResults: false,
    }
  },

  computed: {
    resultsVarClass() {
      return `tf-cover tf-main-results ${this.showLeadCapture ? 'blurred' : ''}`
    },
    resultsWithScore() {
      const score = this.getStrengthScore()
      const results = JSON.parse(JSON.stringify(this.formatQuizAnswers()))
      results.thesis_sq_strength_score = score.strength_score
      results.thesis_sq_current_percentile = `${score.demographic_comparison}${this.getOrdinal(parseInt(score.demographic_comparison, 10))}`
      results.thesis_sq_projected_percentile = score.demographic_comparison_w_improvement
      results.thesis_sq_projected_date = this.getProjectedDate()
      console.log(results)
      return results
    },
  },
  mounted() {
    document.body.querySelector('.thesis-form').classList.remove('blurred-dots-gradient-bg')
    trackLoadEvent('results.loaded')
    if (this.leadCaptureContent.intro_slot) {
      this.showLeadCapture = false
      this.$emit('quizCompleteEvents', this.resultsWithScore)
    }
  },
  methods: {
    getProjectedDate() {
      const now = new Date()
      const then = new Date(now.setDate(now.getDate() + 90))
      const month = then.toLocaleString('default', { month: 'long' })
      const date = then.getDate()
      const dateOrdinal = this.getOrdinal(parseInt(then.getDate(), 10))
      return `${month} ${date}${dateOrdinal}`
    },
    getOrdinal(d) {
      if (d > 3 && d < 21) return 'th'
      switch (d % 10) {
        case 1: return 'st'
        case 2: return 'nd'
        case 3: return 'rd'
        default: return 'th'
      }
    },
    getStrengthScore() {
      const dm = this.dataMap
      const age = this.results[dm.age].content || '20-29'
      const gender = this.results[dm.gender].content || 'Non-Binary'
      const crunches = this.results[dm.crunches].content || 27
      const pushups = this.results[dm.pushups].content || 25.5
      const squats = this.results[dm.squats].content || 32
      const score = calcQuizResults({
        gender, age, crunches, pushups, squats,
      })
      return {
        strength_score: score.strength_score >= 1000 ? 1000 : score.strength_score,
        demographic_comparison: score.demographic_comparison.toFixed(),
        demographic_comparison_w_improvement: score.demographic_comparison_w_improvement.toFixed(),
      }
    },
    formatQuizAnswers() {
      // format results for submission to Klaviyo
      const dm = this.dataMap
      return {
        thesis_sq_fitness_goals: this.results[dm.fitness_goals].content || '',
        thesis_sq_how_often_do_you_exercise: this.results[dm.how_often_do_you_exercise].content || '',
        thesis_sq_how_often_do_you_train: this.results[dm.how_often_do_you_train].content || '',
        thesis_sq_age: this.results[dm.age].content || '',
        thesis_sq_gender: this.results[dm.gender].content || '',
        thesis_sq_have_you_worked_with_a_personal_trainer: this.results[dm.personal_trainer].content || '',
        thesis_sq_gym_membership: this.results[dm.gym_membership].content || '',
        thesis_sq_what_motivates_you_to_exercise: dm.what_motivates_you_to_exercise ? this.results[dm.what_motivates_you_to_exercise].content : '',
        thesis_sq_fitness_persona: dm.fitness_persona ? this.format_persona_answer() : '',
      }
    },
    format_persona_answer() {
      const personaAnswer = this.results[this.dataMap.fitness_persona].content || ''
      switch (personaAnswer) {
        case 'I struggle to remain consistent; therefore, I need guidance on what and how to do things to stay engaged and committed.':
          return 'Result Chasers'
        case 'Working out with specific goals in mind (training for a race, losing weight, or improving in a sport) helps me stay motivated and feel accomplished.':
          return 'Guidance Getters'
        case 'Being fit is an important part of who I am today; I have a routine that works well for me, but am always looking for ways to challenge myself.':
          return 'Fitness Fanatics'
        default:
          return ''
      }
    },
    handleSubmit(event) {
      this.showLeadCapture = false
      const email = event.target.email.value
      const phone = event.target.phone && event.target.phone.value
      this.$emit('quizCompleteEvents', this.resultsWithScore, email, phone)
      if (window.location.pathname === '/strength-score/10') {
        window._mfq.push(['newPageView', '/strength-score/10/results/'])
      }
    },
  },
}
</script>
