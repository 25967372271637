<template>
  <div class="outer-loader-wrapper">
    <div class="loader-wrapper">
      <div
        v-if="content"
        class="loader-content"
      >
        <TitleBlock
          :kicker="content.kicker"
          :title="content.title"
          :subtitle="content.subtitle"
          :text="content.text"
        />
        <p class="preparing-results">
          Preparing Results
        </p>
        <loading-bar
          v-if="hasLoader"
          :delay="content.delay"
        />
      </div>
      <div class="image-block">
        <img
          v-if="content.image"
          :src="content.image"
        >
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import * as types from '../../action-types'
import TitleBlock from './TitleBlock.vue'
import LoadingBar from './LoadingBar.vue'
import { trackLoadEvent } from '../../utils/tracking/index'

export default {
  components: {
    TitleBlock,
    LoadingBar,
  },

  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    quizData() { return this.$store.getters.QUIZDATA || false },
    hasLoader() { return this.quizData.loader.delay },
  },

  created() {
    document.body.querySelector('.thesis-form').classList.add('blurred-dots-gradient-bg')
    window.scrollTo(0, 0)
  },

  mounted() {
    this.loadingTimer()
    trackLoadEvent('results.loading')
  },

  beforeDestroy() {
    this.$root.$store.dispatch(types.SET_LOADED)
  },
  methods: {
    loadingTimer() {
      const { delay } = this.quizData.loader
      if (delay === 0 || typeof delay !== 'number') {
        this.$emit('loaded')
      } else {
        setTimeout(() => this.$emit('loaded'), delay)
      }
    },
  },
}
</script>
