<template>
  <div class="tf-results">
    <transition
      v-if="showLeadCapture && !leadCaptureContent.intro_slot"
      name="fade"
      mode="out-in"
    >
      <LeadCapture
        :content="leadCaptureContent"
        @handleSubmit="handleSubmit"
      />
    </transition>
    <transition
      v-else
      name="fade"
      mode="out-in"
    >
      <CostCalculation
        :savings="savings"
        :content="content"
      />
    </transition>
  </div>
</template>

<script>
import LeadCapture from '../../shared/LeadCapture.vue'
import CostCalculation from './CostCalculation.vue'
import { calculateSavings } from '../../../utils/savings-calc'

export default {
  components: {
    LeadCapture,
    CostCalculation,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    leadCaptureContent: {
      type: Object,
      default: () => {},
    },
    dataMap: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Array,
      default: () => [],
    },
    quizCompleteEvents: {
      type: Function,
      default: () => console.log('pass quizCompleteEvents prop to submit tracking events'),
    },
  },

  data() {
    return {
      showLeadCapture: true,
      showEmailError: false,
      showMainResults: false,
    }
  },

  computed: {
    skipQuizButton() {
      return this.$root.$store.getters.SKIP_QUIZ_BUTTON
    },
    formattedResults() {
      const dm = this.dataMap
      return {
        thesis_mq_number_of_members: this.results[dm.members].content || 0,
        thesis_mq_number_of_trainings: this.results[dm.trainings].content || 0,
        thesis_mq_number_of_classes: this.results[dm.classes].content || 0,
        thesis_mq_number_of_yoga: this.results[dm.yoga].content || 0,
        thesis_mq_fitness_goals: this.results[dm.fitness_goals].content || '',
        thesis_mq_how_often_do_you_exercise: this.results[dm.how_often_do_you_exercise].content || '',
        thesis_mq_what_motivates_you_to_exercise: this.results[dm.what_motivates_you_to_exercise].content || '',
      }
    },
    savings() {
      const results = this.formattedResults
      return calculateSavings({
        gymMemberCount: parseInt(results.thesis_mq_number_of_members, 10),
        personalTrainerCount: parseInt(results.thesis_mq_number_of_trainings, 10),
        fitnessClassCount: parseInt(results.thesis_mq_number_of_classes, 10),
        yogaClassCount: parseInt(results.thesis_mq_number_of_yoga, 10),
      })
    },
  },
  mounted() {
    if (this.leadCaptureContent.intro_slot) {
      this.formSubmitted()
      this.$emit('quizCompleteEvents', this.formattedResults)
    }
  },
  methods: {
    formSubmitted() {
      const app = document.getElementById('app')
      app.classList.remove('blurred-dots-gradient-bg')
      app.classList.add('black-bg')
      app.style.background = 'black'
      this.showLeadCapture = false
    },
    handleSubmit(e) {
      this.formSubmitted()
      const email = e.target.email.value
      const phone = e.target.phone ? e.target.phone.value : ''
      this.$emit('quizCompleteEvents', this.formattedResults, email, phone)
    },
  },
}
</script>
