import Vue from "vue";
import App from '../../javascript/App.vue'
import Vuex from "vuex";
import NorthForm from "@sheridan-media/north-form";
import store from '../store/index'
import Vuelidate from 'vuelidate'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);


Vue.use(Vuex);
Vue.use(NorthForm, { store: store });
Vue.use(Vuelidate)
Vue.config.productionTip = false;

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('north-form')
  if (element) {
    const quizData = JSON.parse(element.dataset.quiz)
    Vue.prototype.$quizData = quizData;
    new Vue({
      el: '#north-form',
      store,
      render: h => h(App)
    });
  }
});