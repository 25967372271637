<template>
  <div class="meter">
    <span style="width:100%;">
      <span
        class="progress-bar"
        :style="{animationDuration: `${this.delay/1000}s`}"
      />
    </span>
  </div>
</template>
<script>

export default {
  props: {
    delay: {
      type: Number,
      default: 5000,
    },
  },
}
</script>

<style lang="scss" scoped>
  .meter {
    height: 1.5rem;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 0.75rem;
    border: 0.0625rem solid #C4C4C4;
    width: 100%;

    @media (min-width: 48rem) {
      max-width: 40rem;
      margin: auto;
    }

    @media (min-width: 80rem) {
      margin-top: 0;
    }
  }

  .meter span {
    display: block;
    height: 100%;
  }

  .progress-bar {
    background-color: #70EADD !important;
    -webkit-animation: progressBar ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation: progressBar ease-in-out;
    -moz-animation-fill-mode: both;
    animation: progressBar ease-in-out;
    animation-fill-mode: both;
  }

  @-webkit-keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @-moz-keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }
</style>
