<template>
  <div :class="classes">
    <Quiz />
  </div>
</template>

<script>
import Quiz from './containers/Quiz.vue'

export default {
  components: {
    Quiz,
  },
  computed: {
    path() { return window.location.pathname },
    quizData() { return this.$store.getters.QUIZDATA },
    classes() { return `thesis-onsite ${this.$quizData.classes || ''}` },
    componentType() { return this.quizData.component },
  },

  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
  mounted() {
    window.analytics.page('Home')
    window.analytics.track('thesis_quiz_start')
  },
}
</script>

<style lang="scss">
* { margin: 0; padding: 0; box-sizing: border-box; }

// Vue Transitions
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
