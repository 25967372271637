<template>
  <div class="range">
    <svg style="height:0">
      <defs>
        <linearGradient
          id="gradient"
          x1="0%"
          y1="90%"
          x2="0%"
          y2="0%"
        >
          <stop
            offset="0%"
            style="stop-color: rgb(0, 0, 0); stop-opacity: 0"
          />
          <stop
            offset="30%"
            style="stop-color: rgb(112, 234, 221); stop-opacity: 1"
          />
          <stop
            offset="100%"
            style="stop-color: rgb(112, 234, 221); stop-opacity: 1"
          />
        </linearGradient>
      </defs>
    </svg>
    <round-slider
      v-model="score"
      startAngle="315"
      endAngle="+270"
      lineCap="round"
      radius="120"
      width="14"
      max="1000"
      readOnly="true"
      handleSize="+10"
      pathColor="rgba(0, 0, 0, 1)"
      rangeColor="url(#gradient)"
    />
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'

export default {
  components: {
    RoundSlider,
  },

  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss">
  .range {
    height: 14.375rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 48rem) {
      height: 18.4375rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .rs-control {
    margin: auto;
    transform: scale(0.8);

    @media (min-width: 48rem) {
      transform: none;
    }
  }

  .rs-container {
    display: flex;
  }

  .rs-tooltip.rs-tooltip-text {
    font-family: 'GT America', sans-serif;
    font-weight: 500;
    font-size: 3.5rem;
    line-height: 3.5rem;
    position: relative;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    transform: translate(-50%, -50%);
    align-self: center;
    justify-self: center;
    width: 100%;
    transform: none;
    letter-spacing: -0.05em;
    background: linear-gradient(180deg, #70EADD 40.23%, rgba(112, 234, 221, 0) 158.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media (min-width: 767px) {
      font-size: 4.2rem;
      line-height: 4.1875rem;
    }
    @media (min-width: 1024px) {
      font-size: 4.9rem;
      line-height: 4.875rem;
    }
  }

  .rs-handle.rs-move {
    position: absolute;
    bottom: 0;
    height: 0rem;
    top: 50%;
    background: none;
    box-shadow: none;

    &:after {
      content: "";
      height: 2.375rem;
      width: 2.375rem;
      border-radius: 50%;
      border: 0.5625rem solid #000;
      position: absolute;
      background-color: #70EADD;
      left: 0;
    }
  }
</style>
