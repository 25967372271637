const avgGymMembership = 58
const personalTrainerPrice = 50
const fitnessClassPrice = 20
const yogaClassPrice = 15

export function calculateSavings({
  gymMemberCount,
  personalTrainerCount,
  fitnessClassCount,
  yogaClassCount,
}) {
  const totalGymCost = avgGymMembership * gymMemberCount || 0
  const totalTrainerCost = personalTrainerPrice * personalTrainerCount || 0
  const totalFitnessCost = fitnessClassPrice * fitnessClassCount || 0
  const totalYogaCost = yogaClassPrice * yogaClassCount || 0
  const sum = totalGymCost + totalTrainerCost + totalFitnessCost + totalYogaCost

  console.log(`Gym:     $${avgGymMembership} * ${gymMemberCount} = $${totalGymCost}`)
  console.log(`Trainer: $${personalTrainerPrice} * ${personalTrainerCount} = $${totalTrainerCost}`)
  console.log(`Fitness: $${fitnessClassPrice} * ${fitnessClassCount} = $${totalFitnessCost}`)
  console.log(`Yoga:    $${yogaClassPrice} * ${yogaClassCount} = $${totalYogaCost}`)
  console.log(`$${totalGymCost} + $${totalTrainerCost} + $${totalFitnessCost} + $${totalYogaCost} = $${sum}`)

  return sum
}

