const movement_data = {
    "pushups": {
        "Male": {
            "age_0": {
                "5": 10,
                "20": 20,
                "50": 34,
                "80": 45,
                "99": 55
            },
            "age_1": {
                "5": 7,
                "20": 15,
                "50": 24,
                "80": 35,
                "99": 45
            },
            "age_2": {
                "5": 6,
                "20": 12,
                "50": 20,
                "80": 30,
                "99": 40
            },
            "age_3": {
                "5": 4,
                "20": 8,
                "50": 15,
                "80": 25,
                "99": 35
            },
            "age_4": {
                "5": 2,
                "20": 5,
                "50": 10,
                "80": 20,
                "99": 30
            }
        },
        "Female": {
            "age_0": {
                "5": 3,
                "20": 6,
                "50": 17,
                "80": 34,
                "99": 50
            },
            "age_1": {
                "5": 2,
                "20": 4,
                "50": 12,
                "80": 25,
                "99": 35
            },
            "age_2": {
                "5": 1,
                "20": 3,
                "50": 8,
                "80": 20,
                "99": 35
            },
            "age_3": {
                "5": 1,
                "20": 2,
                "50": 6,
                "80": 15,
                "99": 30
            },
            "age_4": {
                "5": 0,
                "20": 1,
                "50": 3,
                "80": 5,
                "99": 20
            }
        },
        "Non-Binary": {
            "age_0": {
                "5": 6,
                "20": 13,
                "50": 25.5,
                "80": 39.5,
                "99": 52.5
            },
            "age_1": {
                "5": 4.5,
                "20": 9.5,
                "50": 18,
                "80": 30,
                "99": 40
            },
            "age_2": {
                "5": 3.5,
                "20": 7.5,
                "50": 14,
                "80": 25,
                "99": 38.5
            },
            "age_3": {
                "5": 2.5,
                "20": 5,
                "50": 10.5,
                "80": 20,
                "99": 32.5
            },
            "age_4": {
                "5": 1,
                "20": 3,
                "50": 6.5,
                "80": 12.5,
                "99": 25
            }
        }
    },
    "crunches": {
        "Male": {
            "age_0": {
                "5": 6,
                "20": 13,
                "50": 27,
                "80": 56,
                "99": 75
            },
            "age_1": {
                "5": 6,
                "20": 13,
                "50": 31,
                "80": 69,
                "99": 75
            },
            "age_2": {
                "5": 10,
                "20": 21,
                "50": 39,
                "80": 75,
                "99": 80
            },
            "age_3": {
                "5": 6,
                "20": 13,
                "50": 27,
                "80": 60,
                "99": 74
            },
            "age_4": {
                "5": 2,
                "20": 4,
                "50": 16,
                "80": 33,
                "99": 53
            }
        },
        "Female": {
            "age_0": {
                "5": 6,
                "20": 12,
                "50": 27,
                "80": 45,
                "99": 70
            },
            "age_1": {
                "5": 6,
                "20": 12,
                "50": 21,
                "80": 43,
                "99": 55
            },
            "age_2": {
                "5": 3,
                "20": 5,
                "50": 25,
                "80": 42,
                "99": 55
            },
            "age_3": {
                "5": 3,
                "20": 5,
                "50": 9,
                "80": 30,
                "99": 48
            },
            "age_4": {
                "5": 2,
                "20": 4,
                "50": 9,
                "80": 30,
                "99": 48
            }
        },
        "Non-Binary": {
            "age_0": {
                "5": 6,
                "20": 12.5,
                "50": 27,
                "80": 49.5,
                "99": 72.5
            },
            "age_1": {
                "5": 6,
                "20": 12.5,
                "50": 26,
                "80": 55.5,
                "99": 65.5
            },
            "age_2": {
                "5": 6.5,
                "20": 13,
                "50": 32,
                "80": 58.5,
                "99": 67.5
            },
            "age_3": {
                "5": 4.5,
                "20": 9,
                "50": 18,
                "80": 45,
                "99": 61
            },
            "age_4": {
                "5": 2,
                "20": 4,
                "50": 12.5,
                "80": 31.5,
                "99": 55.5
            }
        }
    },
    "squats": {
        "Male": {
            "age_0": {
                "5": 12.5,
                "20": 25,
                "50": 35,
                "80": 44,
                "99": 50
            },
            "age_1": {
                "5": 11,
                "20": 22,
                "50": 31,
                "80": 40,
                "99": 46
            },
            "age_2": {
                "5": 8.5,
                "20": 17,
                "50": 27,
                "80": 35,
                "99": 42
            },
            "age_3": {
                "5": 4.5,
                "20": 9,
                "50": 17,
                "80": 25,
                "99": 32
            },
            "age_4": {
                "5": 3.5,
                "20": 7,
                "50": 15,
                "80": 22,
                "99": 29
            }
        },
        "Female": {
            "age_0": {
                "5": 9,
                "20": 18,
                "50": 29,
                "80": 37,
                "99": 44
            },
            "age_1": {
                "5": 6.5,
                "20": 13,
                "50": 25,
                "80": 33,
                "99": 40
            },
            "age_2": {
                "5": 3.5,
                "20": 7,
                "50": 19,
                "80": 27,
                "99": 34
            },
            "age_3": {
                "5": 2.5,
                "20": 5,
                "50": 14,
                "80": 22,
                "99": 28
            },
            "age_4": {
                "5": 2,
                "20": 3,
                "50": 10,
                "80": 18,
                "99": 25
            }
        },
        "Non-Binary": {
            "age_0": {
                "5": 10.5,
                "20": 21.5,
                "50": 32,
                "80": 40.5,
                "99": 47
            },
            "age_1": {
                "5": 8.5,
                "20": 17.5,
                "50": 33,
                "80": 36.5,
                "99": 43
            },
            "age_2": {
                "5": 6,
                "20": 12,
                "50": 23,
                "80": 31,
                "99": 38
            },
            "age_3": {
                "5": 3.5,
                "20": 7,
                "50": 20,
                "80": 23.5,
                "99": 30
            },
            "age_4": {
                "5": 2.5,
                "20": 5,
                "50": 12.5,
                "80": 20,
                "99": 27
            }
        }
    }
};

function get_age_bracket_num(age){
    /*Determine user's age bracket number.
    Args:
    age (int): user age [years]
    Returns:
    bracket_name (str): bracket name; ex: age_1
    */
   var age_cutoffs = ["20-29", "30-39", "40-49", "50-59", "60-69"];
    var bracket_num = age_cutoffs.length - 1;
    for (var i = 0; i < age_cutoffs.length; i++){
        if (age == age_cutoffs[i]) {
            bracket_num = i;
            i = age_cutoffs.length;
        }
    }
    // max out age bracket at 4
    if (bracket_num === 5) {
        bracket_num = 4;
    }
    var bracket_name = "age_" + bracket_num;  
    return bracket_name
} 
function get_user_percentile_data(gender, age, movement_data){
    /*Get age, gender percentile data based on user's input data.
    Args:
    gender (str): user's gender
    age (int): user's age [years]
    movement_data (dict): movement percentile data
    Returns:
    user_percentile_data: dictionary of percentiles for user's age and gender
    */ 
    var age_bracket_num = get_age_bracket_num(age); 
    var exercises = ['pushups', 'crunches', 'squats']
    var user_percentile_data = {}; 
    for (var i = 0; i < 3; i++){
        user_percentile_data[exercises[i]] = movement_data[exercises[i]][gender][age_bracket_num];
    }
    return user_percentile_data; 
}
function interpolate_percentile(user_val, lower_percentile, lower_val, upper_percentile, upper_val){
    return lower_percentile + ((user_val - lower_val) / (upper_val - lower_val)) * (upper_percentile - lower_percentile); 
}
function calc_percentile(percentile_data, user_value){
    /*Calculate percentile based on reference data and user reps.
    Args:
    percentile_data (dict): dictionary of benchmark data for movement
    user_value (int): number of reps user can do
    Returns:
    interpolated percentile
    */ 
    var lower_percentile = 1;
    var lower_val = 0;
    var upper_percentile = null;
    var upper_val = null; 
    for (var i = 0; i < Object.keys(percentile_data).length; i++) {
        if (user_value <= Object.values(percentile_data)[i] && upper_percentile == null){
            upper_percentile = Object.keys(percentile_data)[i];
            upper_val = Object.values(percentile_data)[i];
        } else if ( user_value > Object.values(percentile_data)[i]){
            lower_percentile = Object.keys(percentile_data)[i];
            lower_val = Object.values(percentile_data)[i];
        } 
    }
    if (upper_percentile == null){
        return 99;
    }
    
    else{
        return interpolate_percentile(user_value, parseInt(lower_percentile), lower_val, parseInt(upper_percentile), upper_val); 
    }
} 

function calc_strength_score_from_movements(user_input_data){
    /*Calculate strength score from user input data.
    Args:
    user_input_data (dict): user input data, including reps per movement
    Returns:
    user_strength_score [float]: user strength score
    */ 
    var user_pushups = user_input_data["pushups"];
    var user_crunches = user_input_data["crunches"];
    var user_squats = user_input_data["squats"];
    // Pushups / Upper Body
    var upper_body = 179 + (14.2 * user_pushups);
    // Crunches / Core
    var core = 74 + (18.5 * user_crunches);
    // Squats / Lower Body
    var lower_body = 206 + (14.8 * user_squats);
    var user_strength_score =  Math.floor((upper_body + core + lower_body)/3); 
    return user_strength_score; 
}

function calc_user_avg_percentile(movement_data, user_input_data){
    /* Calculate user's average percentile from three movements (pushups, crunches, squats)
    Args:
    movement_data (dict): movement percentile data
    user_input_data (dict): user input data, including reps per movement
    Returns:
    avg user percentile [float]
    */ 
    var user_percentiles = [];
    var exercises = ["pushups", "crunches", "squats"];
    var user_movement_data = get_user_percentile_data(user_input_data["gender"], user_input_data["age"], movement_data);
    for (var i = 0; i < exercises.length; i++){
        var movement_percentiles = user_movement_data[exercises[i]]; 
        user_percentiles.push(calc_percentile(movement_percentiles, user_input_data[exercises[i]])); 
    }
    const arrAvg = user_percentiles.reduce((a,b) => a + b, 0) / user_percentiles.length;
    return arrAvg; 
}

function calcQuizResults(user_input_data){
    /*Calculate quiz results for user.
    Args:
    user_input_data (dict): user input data, including reps per movement, age, and gender
    Returns:
    response [dict]: results including: user strength score, percentile among age/gender bracket, and percentile based on 25% strength improvement
    */ 
    var user_avg_percentile = calc_user_avg_percentile(movement_data, user_input_data) || 50; 
    var user_score = calc_strength_score_from_movements(user_input_data) || 500; 
    var user_improved_input_data = user_input_data; 
    user_improved_input_data['pushups'] = user_improved_input_data['pushups'] * 1.25;
    user_improved_input_data['crunches'] = user_improved_input_data['crunches'] * 1.25;
    user_improved_input_data['squats'] = user_improved_input_data['squats'] * 1.25;
    var user_improved_avg_percentile = calc_user_avg_percentile(movement_data, user_improved_input_data); 
    var response = {
        'strength_score': user_score,
        'demographic_comparison': user_avg_percentile,
        'demographic_comparison_w_improvement': user_improved_avg_percentile
    }; 
    return response; 
}

export { calcQuizResults }