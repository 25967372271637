<template>
  <div class="result-score-details">
    <h3>Your Strength Score is in the <span id="results-current-percentile"> {{ `${demographic_comparison}${this.getOrdinal(demographic_comparison)} percentile` }}</span>  of your age and sex</h3>
    <h4
      v-if="strength_score_title"
      class="results-score-title"
      v-html="strength_score_title"
    />
    <Range :score="strength_score" />
    <p class="tf-lead">
      If you started with Tonal today, you would be at least <span class="color-secondary"><span id="results-projected-percentile">25% stronger</span></span> by <span id="results-ninety-days" />
    </p>
    <div
      v-if="content.cta_link && content.cta_text"
      class="tf-cta-block"
    >
      <a
        :href="content.cta_link"
        class="tf-btn"
        @click="trackClickEvent(content.cta_text)"
        v-html="content.cta_text"
      />
    </div>
  </div>
</template>

<script>
import Range from '../../shared/Range.vue'
import ExpandedResults from './ExpandedResults.vue'


export default {
  components: {
    Range,
    ExpandedResults
  },

  props: {
    content: {
      type: Object,
      default: () => {},
    },
    score: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    kicker() { return this.content.kicker },
    title() { return this.content.title },
    strength_score_title() { return this.content.strength_score_title },
    text() { return this.content.text },
    strength_score() { return this.score.strength_score },
    demographic_comparison() { return this.score.demographic_comparison },
  },

  mounted() {
    const ninetyDaysText = document.querySelector('#results-ninety-days')
    if (ninetyDaysText) this.updateNinetyDaysText(ninetyDaysText)
  },

  methods: {
    getOrdinal(d) {
      if (d > 3 && d < 21) return 'th'
      switch (d % 10) {
        case 1: return 'st'
        case 2: return 'nd'
        case 3: return 'rd'
        default: return 'th'
      }
    },

    updateNinetyDaysText(el) {
      const now = new Date()
      const then = now.setDate(now.getDate() + 90)

      const ninetyDaysFromNow = new Date(then)
      const month = ninetyDaysFromNow.toLocaleString('default', { month: 'long' })
      const day = ninetyDaysFromNow.getDate()

      el.innerHTML = `${month}&nbsp;${day}${this.getOrdinal(day)}`
    },
  },
}
</script>
