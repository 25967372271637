<template>
  <section :class="['tf-email-capture', `${theme}-theme`]">
    <TitleBlock
      :kicker="kicker"
      :title="title"
      :subtitle="subtitle"
      :text="text"
    />
    <form @submit.prevent="submitForm">
      <input
        v-model.lazy="$v.email.$model"
        placeholder="Email address"
        type="text"
        name="email"
      >
      <p
        v-if="$v.email.$error"
        class="error-msg"
        v-html="email_error_message"
      />
      <vue-tel-input
        v-if="sms_capture"
        v-model="phone"
        defaultCountry="US"
        :invalidMsg="'Please enter a valid phone number'"
        :inputOptions="{placeholder: 'Phone Number', name: 'phone'}"
      />
      <input
        type="submit"
        :value="ctaText"
        class="tf-btn"
      >
      <small
        v-if="disclaimer"
        class="disclaimer"
        v-html="disclaimer"
      />
      <small
        class="privacy-disclaimer"
        v-html="privacy_disclaimer"
      />
    </form>
  </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import TitleBlock from './TitleBlock.vue'

export default {
  components: { TitleBlock },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    email: {
      email,
      required,
    },
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    handleSubmit: {
      type: Function,
      default: () => console.log('pass submit prop to submit email capture form'),
    },
    results: {
      type: Object,
      default: () => {},
    },
    resultsVar: {
      type: String,
      default: 'var1',
    },
  },

  data() {
    return {
      showEmailError: false,
      email: '',
      phone: '',
    }
  },

  computed: {
    sms_capture() { return this.content.sms_capture },
    kicker() { return this.content.kicker },
    title() { return this.content.title },
    subtitle() { return this.content.subtitle },
    ctaText() { return this.content.cta_text },
    text() { return this.content.text },
    email_error_message() { return this.content.email_error_message },
    disclaimer() { return this.content.disclaimer },
    privacy_disclaimer() { return this.content.privacy_disclaimer },
    theme() { return this.content.theme },
  },

  methods: {
    submitForm(event) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('handleSubmit', event)
      }
    },
  },
}
</script>
