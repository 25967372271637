<template>
  <div class="expanded-results">
    <section class="callout">
      <div class="img-wrapper">
        <img :src="strengthImage">
      </div>
      <div class="content">
        <div class="title-block">
          <h2>Strength score is more than lifting heavier weight.</h2>
          <p>It captures your ability to push, pull, go faster, and move with confidence.</p>
        </div>
        <div class="cta-block">
          <p class="kicker">
            Learn more about tonal Strength Score.
          </p>
          <a
            class="tf-button"
            :href="content.cta_link"
          >Learn More</a>
        </div>
      </div>
    </section>
    <section class="text-callout bg-primary-gray">
      <h2 class="main-title">
        Tonal takes the guesswork out of strength training.
      </h2>
      <div class="content">
        <ul class="list-block">
          <li>Adaptive Weight that adjusts with your strength</li>
          <li>Personalized workouts tailored to your needs</li>
          <li>2000+ workouts for any goal</li>
          <li>Real-time progress tracking to keep you motivated</li>
        </ul>
        <div class="cta-block">
          <p class="kicker">
            Try it free for 30 days.
          </p>
          <a
            class="tf-button"
            :href="content.cta_link"
          >Join Tonal</a>
        </div>
      </div>
    </section>

    <section class="carousel">
      <div class="breadcrumbs-wrapper">
        <div
          ref="breadcrumbs"
          class="logo-breadcrumbs"
        >
          <div
            v-for="(review, index) in carousel"
            :key="review.key"
            ref="breadcrumb"
            :class="`logo ${review.active ? 'active' : 'inactive'}`"
            @click="() => setActiveSlide(index)"
          >
            <img
              :src="review.icon"
              alt=""
            >
          </div>
        </div>
      </div>
      <hr>

      <div class="reviews">
        <div
          v-for="review in carousel"
          :key="review.key"
          :class="`review ${review.active ? 'active' : 'inactive'}`"
        >
          <h2 v-if="review.title">
            {{ review.title }}
          </h2>
          <p v-if="review.text">
            {{ review.text }}
          </p>
        </div>
      </div>
    </section>

    <section class="details bg-primary-gray">
      <div class="title-block">
        <h2 class="main-title">
          Become your fittest self.
        </h2>
        <p>We put as much care into ensuring a perfect Tonal experience as we do into designing the perfect home gym.</p>
      </div>
      <div class="content">
        <div class="detail">
          <img :src="installationIcon">
          <div class="copy">
            <h2>Professional Installation</h2>
            <p>We’ll deliver and set up everything so all you have to think about is your first workout.</p>
          </div>
        </div>
        <div class="detail">
          <img :src="trialIcon">
          <div class="copy">
            <h2>30-Day Home Trial</h2>
            <p>Work it out. Sweat it out. If you don’t love it, we’ll take it back. No questions asked.</p>
          </div>
        </div>
        <div class="detail">
          <img :src="warrantyIcon">
          <div class="copy">
            <h2>3-Year Limited Warranty</h2>
            <p>We stand behind the power of Tonal. Our team is happy to help with any issues that arise.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="callout final-cta">
      <div class="cta-block">
        <a
          class="tf-button"
          :href="content.cta_link"
        >Try Tonal for 30 Days</a>
      </div>
    </section>
  </div>
</template>

<script>
import strengthImage from '../../../../assets/images/strength-quiz/strength-score.png'
import installationIcon from '../../../../assets/images/strength-quiz/installation.png'
import trialIcon from '../../../../assets/images/strength-quiz/trial.png'
import warrantyIcon from '../../../../assets/images/strength-quiz/warranty.png'
import Enews from '../../../../assets/images/strength-quiz/Enews.png'
import MensHealth from '../../../../assets/images/strength-quiz/MensHealth.png'
import Outside from '../../../../assets/images/strength-quiz/Outside.png'
import SI from '../../../../assets/images/strength-quiz/SI.png'
import WomensHealth from '../../../../assets/images/strength-quiz/WomensHealth.png'

export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      strengthImage,
      installationIcon,
      trialIcon,
      warrantyIcon,
      breadcrumbWidth: 0,
      carousel: [
        {
          key: 'MensHealth',
          active: true,
          icon: MensHealth,
          title: 'Editor’s Pick: Best Home Cable Machine',
          text: '“The ideal at-home muscle-building machine. The magnetic resistance isolates muscles for a killer pump. Bonus: It doubles as a gym jukebox, too.”',
        },
        {
          key: 'WomensHealth',
          active: false,
          icon: WomensHealth,
          title: 'Best for Strength Training Sans Weights',
          text: '“Owning a Tonal is like having a spotter and a strength coach on demand.”',
        },
        {
          key: 'SI',
          active: false,
          icon: SI,
          text: '“The best part of Tonal is its incredible versatility. Most home workout equipment requires access to dumbbells, bands, adjustable benches, yoga mats and more to train every muscle in your body. Tonal is the complete package.”',
        },
        {
          key: 'Enews',
          active: false,
          icon: Enews,
          text: '“Imagine having a live-in personal trainer and an entire gym that takes up less than two feet of wall space! Well, Tonal made it happen!”',
        },
        {
          key: 'Outside',
          active: false,
          icon: Outside,
          text: '“In the nine months that I’ve been exercising exclusively on Tonal, I’ve completed 100 workouts, lifted 1.05 million pounds, and grown 71 percent stronger.”',
        },
      ],
    }
  },
  computed: {
    currentActiveSlide() {
      return this.carousel.find(slide => slide.active)
    },

  },
  mounted() {
    this.setBreadcrumbWidth()
  },
  methods: {
    setActiveSlide(index) {
      const prevActive = this.currentActiveSlide
      prevActive.active = false
      this.carousel[index].active = true
      this.setBreadcrumbWidth()
      this.setTransform(index)
    },

    setBreadcrumbWidth() {
      this.breadcrumbWidth = this.$refs.breadcrumb[0].clientWidth
      console.log(this.breadcrumbWidth)
    },
    setTransform(index) {
      const { breadcrumbs } = this.$refs
      breadcrumbs.style.transform = `translateX(calc(50% - ${this.breadcrumbWidth / 2 + index * this.breadcrumbWidth}px))`
    },
  },
}
</script>
