<template>
  <section class="tf-cover tf-main-results">
    <div class="tf-cover-content">
      <div class="card white-bg" v-if="savings > 112">
        <h3>With Tonal, you can&nbsp;save</h3>
        <p class="usd" v-html="`$${savings - 112}`" />
        <p class="label">monthly</p>
      </div>

      <div class="card white-bg" v-else>
        <h3>Your household fitness costs&nbsp;are:</h3>
        <p class="usd" v-html="`$${savings}`" />
        <p class="label">monthly</p>
      </div>

      <div class="card">
        <h3>You can replace all of your household fitness needs with Tonal for&nbsp;only</h3>
        <p class="usd">
          $112<sup>*</sup>
        </p>
        <p class="label">
          monthly
        </p>
      </div>

      <p class="pricing-details">
        Finance through Affirm and pay $63* a month for Tonal and $49/month for the membership.
      </p>
      <div
        v-if="content.cta_link && content.cta_text"
        class="tf-cta-block"
      >
        <a
          :href="content.cta_link"
          target="_top"
          class="tf-btn"
          @click="trackClick(content.cta_text)"
          v-html="content.cta_text"
        />
      </div>
      <small class="disclaimer">*Rates are 0% APR and $0 down based on credit and subject to an eligibility check. For example, $2,995 purchase might cost $62.39/mo over 48 months at 0% APR, membership, accessories, and delivery & installation not included. Payment options through Affirm are provided by these lending partners: affirm.com/lenders. Options depend on your purchase amount, and a down payment may be required.</small>
    </div>
    <figure v-if="content.image">
      <img :src="content.image">
    </figure>
  </section>
</template>

<script>
import { trackClickEvent } from '../../../utils/tracking/index'

export default {
  props: {
    savings: {
      type: Number,
      default: 0
    },
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    trackClick(text) {
      trackClickEvent(text)
    },
  }

}
</script>